<script>import { faBars } from "@fortawesome/free-solid-svg-icons";
import Colorway from "./colorway.svelte";
import IconAccount from "./icon-account.svelte";
import IconCart from "./icon-cart.svelte";
import TopNav from "./top-nav.svelte";
import { getEdgeConfigContext } from "../context";
import EdgeImage from "../edge-image.svelte";
import FaIcon from "../fa-icon.svelte";
export let admin;
export let imageUrlBase;
export let loading;
export let navigation;
export let open;
export let tenantId;
const { text, visual } = getEdgeConfigContext();
const logos = [
    {
        id: "cl4z7jxlv01373yib0sqo5id8",
        src: "https://www.arcticfxgraphics.com/wp-content/uploads/arctic-branding.webp",
        href: "https://www.arcticfxgraphics.com/",
        alt: "ArcticFX Graphics",
    },
    {
        id: "cl2kkjl850051hmi04swlrevc",
        src: "https://www.utvfxgraphics.com/wp-content/uploads/2022/05/imgpsh_fullsize_anim.png",
        href: "https://www.utvfxgraphics.com/",
        alt: "UTVFX Graphics",
    },
    {
        id: "cl8n1ot5h000109l78u10f7x5",
        src: "https://www.motofxgraphics.com/wp-content/uploads/2022/10/MOTO_FX_LOGO_FOR_HOMEPAGE.png",
        href: "https://www.motofxgraphics.com/",
        alt: "MotoFX Graphics",
    },
];
</script>

{#if admin}
  <nav class="bg-gray-7 text-white" aria-label="Admin">
    <ul class="flex">
      <li class="hover:bg-gray-6">
        <a class="block pxxy-1" href="/wp-admin/">Wordpress Admin</a>
      </li>
      <li class="hover:bg-gray-6">
        <a class="block pxxy-1" href="/fx-admin/dashboard">FXWrapr Dashboard</a>
      </li>
      <li class="hover:bg-gray-6">
        <a class="block pxxy-1" href="/fx-admin/analytics">FXWrapr Analytics</a>
      </li>
    </ul>
  </nav>
{/if}
<div class="font-medium">
  <div class="flex justify-end gap-2 pxxy-1 bg-white">
    {#each logos.filter(({ id }) => id !== tenantId) as { href, src, alt }}
      <a class="hover:underline" rel="nofollow" target="_blank" {href}>
        <img class="h-5 object-contain" {src} {alt} />
      </a>
    {/each}
  </div>
  <div class="p-2 xl:px-6 bg-white text-sm flex items-center place-content-between gap-2">
    <a href="/" data-sveltekit-reload title={text?.company}>
      {#if visual?.logos?.nav}
        <EdgeImage
          class="w-auto max-h-10 object-contain"
          image={visual.logos.nav}
          alt={text?.company ?? ""}
        />
      {:else}
        <span>{text?.company ?? ""}</span>
      {/if}
    </a>
    <TopNav {navigation} />
    <nav class="flex-center gap-8" aria-label="User">
      <Colorway class="lt-md:hidden" />
      <ul class="flex gap-8">
        <IconCart class="lt-xs:hidden flex-center gap-2" {imageUrlBase} {loading} />
        <IconAccount class="lt-sm:hidden flex-center gap-2" />
      </ul>
      <button
        type="button"
        class="lg:hidden p-2"
        title="Open Navigation Menu"
        aria-expanded={open ? "true" : "false"}
        on:click={() => (open = true)}
      >
        <FaIcon icon={faBars} />
      </button>
    </nav>
  </div>
</div>
